import React from "react"
import ZzzLogoAnimation from "@components/artworks/logo-design/zzz-logo/svg-components/zzz_logo_animation";

const ZzzLogo = () => {

  return (
    <>
      <ZzzLogoAnimation />
    </>
  )
}

export default ZzzLogo;
